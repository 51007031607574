<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_search">
          <span
            >操作内容：
            <el-input
              placeholder="请输入内容"
              clearable
              v-model="parameter.title"
              class="ipt_width"
            >
            </el-input
          ></span>
          <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
            >搜索</el-button
          >
          <el-button type="warning" icon="el-icon-refresh" @click="handleReset"> 清空</el-button>
        </div>
        <div class="fr">
          <!-- <el-button type="primary" icon="el-icon-plus" @click="Add()"
            >新增</el-button
          > -->
        </div>
      </div>
      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" label="序号"></el-table-column>
            <el-table-column prop="loginName" align="center" label="登录名"></el-table-column>
            <el-table-column align="center" prop="userName" label="用户名"></el-table-column>
            <el-table-column prop="title" align="center" label="操作"></el-table-column>
            <el-table-column
              prop="createdDate"
              align="center"
              sortable
              label="时间"
            ></el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  data() {
    return {
      form: {},
      tableData: [],
      loading: false,
      parameter: {
        pageNow: 1,
        pageSize: 50,
        total: 0,
        title: '',
      },
      pageSize: 0,
    }
  },
  created() {
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
  },
  methods: {
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.title = ''
      this.getData() /** 加载 "付款申请审核" 数据 **/
    },
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageNow = 1
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    getData() {
      this.loading = true
      this.$api.user
        .listLoginLog(this.parameter)
        .then(res => {
          this.loading = false
          let arr = []
          let data = res.data.records
          for (let i = 0; i < data.length; i++) {
            const e = data[i]

            var date = new Date(e.createdDate) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
            var Y = date.getFullYear() + '-'
            var M =
              (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
            var D = date.getDate() + ' '
            var h = date.getHours() + ':'
            var m = date.getMinutes() + ':'
            var s = date.getSeconds()

            e.createdDate = Y + M + D + h + m + s

            arr[i] = e
          }

          this.tableData = arr
          this.parameter.total = res.data.total
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
</style>
